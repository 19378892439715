// src/components/login/Login.js
import React, { useEffect, useState } from 'react';
import { loginUser } from './api';
import './Login.css';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleAutoLogin = async (username, password) => {
    try {
      const response = await loginUser(username, password);
      if (response.success) {
        onLoginSuccess();
      } else {
        setError(response.message || 'Ошибка авторизации');
      }
    } catch (err) {
      setError('Ошибка подключения к серверу');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(username, password);
      if (response.success) {
        localStorage.setItem(
          'authData',
          JSON.stringify({ username, password })
        );
        onLoginSuccess();
      } else {
        setError(response.message || 'Ошибка авторизации');
      }
    } catch (err) {
      setError('Ошибка подключения к серверу');
    }
  };

  useEffect(() => {
    const savedAuthData = JSON.parse(localStorage.getItem('authData'));
    if (savedAuthData) {
      setUsername(savedAuthData.username);
      setPassword(savedAuthData.password);
      handleAutoLogin(savedAuthData.username, savedAuthData.password);
    }
  }, []);

  return (
    <div className="login-container">
      <h2>Вход</h2>
      <form onSubmit={handleLogin}>
        <label>
          Логин:
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </label>
        <label>
          Пароль:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </label>
        {error && <p className="error">{error}</p>}
        <button type="submit">Войти</button>
      </form>
    </div>
  );
}

export default Login;
